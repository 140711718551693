import styled from 'styled-components'
import React from 'react'

export const TitleColumn = styled.div`
	max-width: 930px;
	margin-bottom: 24em;

	@media (max-width: 1024px) {
		margin-bottom: 15.6em;
	}

	@media (max-width: 525px) {
		margin-bottom: 9em;
	}
`

export const Header = styled.h3`
	font-size: 2.625rem;
	line-height: 3.75rem;

	@media (max-width: 1024px) {
		font-size: 2rem;
		line-height: 2.75rem;
	}

	@media (max-width: 525px) {
		max-width: 322px;
		font-size: 30px;
		line-height: 44px;
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
