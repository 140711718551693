import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Styles
import { Header, TitleColumn } from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProjectList from '../../templates/projectList'
import Container from '../../components/ui/Container'
import Heading from '../../components/ui/Heading'

const Projects = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query Projects {
			timacum {
				page(id: "5f45254b3ca499439dcb4980") {
					Title
					Seo {
						MetaDescription
						TitleTag
					}
					Fields {
						... on TIMACUM_ComponentContentTitle {
							Title
						}
					}
				}
				projects {
					id
					Title
					Client
					Seo {
						Url
					}
					Type
					ArchiveDescription
					Tags {
						Title
					}
					GradientDark
					GradientLight
					ArchiveImage {
						url
						alternativeText
						caption
						imageFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 1920) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					ArchiveImageMobile {
						url
						alternativeText
						caption
						imageFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 1920) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					Logo {
						url
						alternativeText
						caption
						imageFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 600, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	`)

	const { Seo } = data.timacum.page
	const { projects } = data.timacum

	return (
		<Layout location={props.location}>
			<SEO data={Seo} />
			<Container>
				<TitleColumn>
					<Header as={Heading} level={1}>
						{data.timacum.page.Fields[0].Title}
					</Header>
				</TitleColumn>
				<ProjectList projects={projects} />
			</Container>
		</Layout>
	)
})

export default Projects
